// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-broadcast-js": () => import("./../../../src/pages/broadcast.js" /* webpackChunkName: "component---src-pages-broadcast-js" */),
  "component---src-pages-developers-js": () => import("./../../../src/pages/developers.js" /* webpackChunkName: "component---src-pages-developers-js" */),
  "component---src-pages-developerservicesagreement-js": () => import("./../../../src/pages/developerservicesagreement.js" /* webpackChunkName: "component---src-pages-developerservicesagreement-js" */),
  "component---src-pages-gaming-js": () => import("./../../../src/pages/gaming.js" /* webpackChunkName: "component---src-pages-gaming-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-metaverse-js": () => import("./../../../src/pages/metaverse.js" /* webpackChunkName: "component---src-pages-metaverse-js" */),
  "component---src-pages-music-js": () => import("./../../../src/pages/music.js" /* webpackChunkName: "component---src-pages-music-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

